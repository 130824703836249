import { createRef, useEffect, useState } from "react";

function Galery() {

    const [images, setImages] = useState([])
    const imagesRef = createRef()
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        async function fetchData() {
            const response = await fetch("/api/gallery", {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            var data = await response.json()
            setImages(data.img)
        }
        fetchData()
    }, []);

    return (
        <div className="gallery">
            <div className="showcase">
                <img src={images[selected]}/>
            </div>
            <div ref={imagesRef} className="images">
                {images.map((url, key) => (
                    <img className={`dropdownMenu ${selected === key ? 'selected' : ''}`} key={key} src={`${url}`} onClick={() => {
                        setSelected(key)
                    }} alt={`Image ${key}`} />
                ))
                }
            </div>
            <button onClick={(e) => {
                imagesRef.current.scrollBy({ left: -200, behavior: 'smooth' });
            }}>{'<'}</button>
            <button onClick={(e) => {
                imagesRef.current.scrollBy({ left: 200, behavior: 'smooth' });
            }}>{'>'}</button>
        </div>
    );
}

export default Galery;
