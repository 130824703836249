import '../css/App.css';
import Header from '../components/header';
import Banner from '../components/banner';
import React, { useState, useEffect } from 'react';
import Footer from '../components/footer'
import OfferList from '../components/offerList';

function CreateOffer({ title }) {

    document.title = title

    const [FreeOffers, setFreeOffers] = useState([])
    const [AcceptedOffers, setAcceptedOffers] = useState([])

    async function acceptOffer(e) {
        const response = await fetch("/api/acceptOffer", {
            method: "POST",
            body: JSON.stringify({ 'offer': e.target.value }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        if(response.ok){
            window.location.replace("/Walker");
        }
    }

    const acceptButton = {
        'style': { backgroundColor: '#247f24', marginTop: '15px', marginLeft: '15px', marginRight: '15px', marginBottom: '15px' },
        'text': 'Jaluta',
        'function': acceptOffer
    }

    useEffect(() => {
        async function fetchData() {
            const response = await fetch("/api/allOffers", {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });

            var data = await response.json()

            console.log(data)

            setAcceptedOffers(data.accepted)
            setFreeOffers(data.unaccepted)
        }
        fetchData()
    }, []);

    return (
        <div className="App">
            <Header />
            <Banner />
            <div style={{ display: 'block' }}>
                <div style={{ display: 'inline-flex', width: '600px', height: '600px' }}>
                    <OfferList offers={FreeOffers} title={'Vabad kuulutused'} button={acceptButton} />
                </div>
                <div style={{ display: 'inline-flex', width: '600px', height: '600px' }}>
                    <OfferList offers={AcceptedOffers} title={'Vastu võetud kuulutused'} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CreateOffer;
