import { createRef, useState } from "react";
import dropbar from './dropbar.svg';

function Dropdown({ jumps }) {

    const [dropdownactive, setDropdownactive] = useState(0)

    function changeTopic() {
        if (dropdownactive == 0) {
            setDropdownactive(1)
        }
        else {
            setDropdownactive(0)
        }
    }

    function jumpToTopic(e) {
        if (dropdownactive == 1) {
            const targetElement = document.getElementById(e.target.value)
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    }

    return (
        <div className='dropdown'>
            <div onClick={changeTopic} style={{ width: '100%', height: '100%' }}>
                <img src={dropbar}></img>
            </div>
            <div className={`dropdownMenu ${dropdownactive === 0 ? 'unactive' : ''}`}>
                <button value={jumps['ÄPP']} onClick={jumpToTopic}>ÄPP</button>
                <button value={jumps['MEIST']} onClick={jumpToTopic}>MEIST</button>
                <button value={jumps['MIKS MEIE?']} onClick={jumpToTopic}>MIKS MEIE</button>
                <button value={jumps['GALERII']} onClick={jumpToTopic}>GALERII</button>
                <button value={jumps['KONTAKT']} onClick={jumpToTopic}>KOKNTAKT</button>
            </div>
        </div>
    );
}

export default Dropdown;
