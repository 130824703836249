import banner from '../components/doltBanner.png';

function Header({ data }) {

    return (
        <div className="Banner">
            <div style={{ height: '140px', width: '100%' }}>
                <img src={banner} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
            </div>
            <div style={{ margin: 'auto', width: '75%', height: '10px', backgroundColor: '#000000' }}></div>
        </div>
    );
}

export default Header;
