import '../css/App.css';
import Header from '../components/header';
import Banner from '../components/banner';
import React, { useState, useEffect, createRef } from 'react';
import Footer from '../components/footer'
import Prices from '../components/prices'
import TransactionMenu from '../components/transactionMenu'
import ImgSelector from '../components/imgSelector';

function CreateOffer({ title }) {

    document.title = title

    const [Time, setTime] = useState(0);

    const [pricing, setPricing] = useState()
    const [price, setPrice] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState(0)

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [image, setImage] = useState(undefined)

    const [userID, setUserID] = useState(NaN);
    const maxSize = 600

    useEffect(() => {
        async function fetchData() {
            const response = await fetch("/api/userInfo", {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            var data = await response.json()
            setUserID(data.id)
            setPhone(data.phone)
            setAddress(data.address)
            setCity(data.city)
        }
        fetchData()
    }, []);

    useEffect(() => {
        function setPrices(time) {
            function linearPrice(time, k) {
                return time * k
            }

            function powerSeriesPrice(time, k, p) {
                return k * (time ** p)
            }

            function discount(realPrice, advertisedPrice) {
                return realPrice - advertisedPrice
            }

            function round(price) {
                return Math.round(price * 10) / 10
            }

            const basePrice = linearPrice(time, pricing.basePrice.k)
            const addedPrice = powerSeriesPrice(time, pricing.addedPrice.k, pricing.addedPrice.p)
            const advertisedPrice = linearPrice(time, pricing.advertisedPrice.k)

            const price = basePrice + addedPrice

            const disc = price - advertisedPrice

            setPrice(round(advertisedPrice))
            setDiscount(round(disc))
            setTotal(round(price))
        }
        async function fetchPricing() {

            const response = await fetch("/api/pricing", {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            var data = await response.json()
            setPricing(data)
        }

        if (pricing == undefined) {
            fetchPricing()
            return
        }
        if (Number.isNaN(Time)){
            console.log('test')
            setPrices(0)
            return
        }
        setPrices(Time)
    }, [Time])

    function getTimeInMinutes(time) {
        var times = time.split(':')
        return (parseInt(times[0]) * 60) + parseInt(times[1])
    }

    function timeChange(e) {
        let startTime = document.getElementById('startTime').value
        let endTime = document.getElementById('endTime').value

        let Time = Math.abs(getTimeInMinutes(startTime) - getTimeInMinutes(endTime))

        if(Number.isNaN(Time)){
            return
        }

        if (startTime > endTime) {
            setEndTime(startTime)
            setStartTime(endTime)
        } else {
            setStartTime(startTime)
            setEndTime(endTime)
        }

        setTime(Time)
    }

    function phoneChange(e) {
        var regex = /[0-9 +]/g;
        var match = e.target.value.match(regex)
        if (match != null && match.length > 0) {
            e.target.value = match.join('')
            regex = /[0-9+]{1,4}|\d{1,4}/g
            match = e.target.value.match(regex)
            if (match != null && match.length > 0) e.target.value = match.join(' ')
        }
        else {
            e.target.value = ''
        }
        setPhone(e.target.value)
    }

    const createOfferPost = async (e) => {
        e.preventDefault();

        let name = document.getElementById('dogName').value
        let phone = document.getElementById('phone').value
        let date = document.getElementById('date').value
        let startTime = document.getElementById('startTime').value
        let endTime = document.getElementById('endTime').value
        let address = document.getElementById('address').value
        let city = document.getElementById('address').value
        let EULA = document.getElementById('DoYouAgree').checked
        let transaction = document.getElementById('trasactionDone').checked
        let output = {
            'name': name, 'phone': phone, 'date': date,
            'startTime': startTime, 'endTime': endTime, 'time': Time,
            'location': address, 'city': city, 'EULA': EULA, 'transaction': transaction,
            'image': image
        }

        const requierd = ['name', 'phone', 'date', 'startTime', 'endTime', 'location', 'city', 'EULA', 'transaction']
        for (var i in requierd) {
            var item = requierd[i]
            var value = output[item]
            if (value === false || value === '') {
                console.log(item)
                document.getElementById('info').style.color = 'red'
                document.getElementById('info').style.fontWeight = '800'
                return
            }
        }

        if (EULA === false) {
            document.getElementById('DoYouAgreeText').style.color = 'red'
            document.getElementById('DoYouAgreeText').style.fontWeight = '800'
            return

        }

        if (transaction === false) {
            document.getElementById('trasactionDoneText').style.color = 'red'
            document.getElementById('trasactionDoneText').style.fontWeight = '800'
            return
        }

        if (Time === 0) {
            document.getElementById('startTime').style.borderColor = 'red'
            document.getElementById('endTime').style.borderColor = 'red'
            return
        }

        if (getTimeInMinutes(startTime) > getTimeInMinutes(endTime)) {
            var a = startTime
            startTime = endTime
            endTime = a
        }

        function hasDatePassed(date) {
            const dateToCheck = new Date(date)
            const today = new Date()
            dateToCheck.setHours(0, getTimeInMinutes(startTime), 0, 0)
            return today > dateToCheck
        }

        if (hasDatePassed(date)) {
            document.getElementById('date').style.borderColor = 'red'
            document.getElementById('startTime').style.borderColor = 'red'
            document.getElementById('endTime').style.borderColor = 'red'
            return
        }

        if (image != undefined) {
            const base64String = image.split(',')[1]
            const byteString = atob(base64String)
            const fileSizeInKB = byteString.length / 1024

            //Its either this or the first algo that makes it so that you cant create a offer without image
            if (fileSizeInKB > 100) {
                document.getElementById('imgSelector').style.borderColor = 'red'
                return
            }
        }

        output = {
            'name': name, 'phone': phone, 'date': date,
            'startTime': startTime, 'endTime': endTime, 'time': Time,
            'location': address, 'city': city, 'EULA': EULA, 'transaction': transaction,
            'image': image
        }

        const response = await fetch("/api/offer", {
            method: "POST",
            body: JSON.stringify(output),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });

        if (response.ok) {
            window.location.href = '/Profile';
        } else {
            document.getElementById('info').style.color = 'red'
            document.getElementById('info').style.fontWeight = '800'
            return
        }
    }

    return (
        <div className="App">
            <Header />
            <Banner />
            <div style={{ display: 'block', textAlign: 'start' }}>
                <div style={{ display: 'inline-flex', minWidth: '320px', width: '75%', maxWidth: '500px' }}>
                    <div className='CreateOfferForm'>
                        <h1 style={{ color: 'black' }}>KUULUTUSE LOOMINE</h1>
                        <div>
                            <span>KOERA NIMI</span>
                            <input id="dogName" type="text" className='input' placeholder='Muhvin' />
                        </div>
                        <div>
                            <span>TELEFON</span>
                            <input id="phone" value={phone} onChange={phoneChange} type="nr" autoComplete='tel' placeholder='+372 5836 4426' />
                        </div>
                        <div>
                            <span>KUUPÄEV</span>
                            <input type='date' onClick={(e) => {
                                if (!e.target.showPickerOpened) {
                                    e.target.showPicker()
                                    e.target.showPickerOpened = true
                                } else {
                                    e.target.showPickerOpened = false
                                }
                            }} onChange={(e) => {
                                e.target.value = e.target.value
                            }} id='date' style={{ backgroundColor: 'white' }}></input>
                        </div>
                        <div>
                            <span >KELLAAEG</span>
                            <input className="timeSelect font12" onChange={timeChange} id='startTime' style={{ backgroundColor: 'white', width: '18%', maxWidth: '65px' }} type="time" />
                            <span className="font18" style={{ marginLeft: '2px', marginRight: '2px' }}>-</span>
                            <input className="timeSelect font12" onChange={timeChange} id='endTime' style={{ backgroundColor: 'white', width: '18%', maxWidth: '65px' }} type="time" />
                        </div>
                        <div>
                            <span>AADRESS</span>
                            <input id='address' value={address} onChange={(e) => { setAddress(e.target.value) }} type='address' placeholder='Suur-Kloostri tn 16' autoComplete="street-address" ></input>
                            <span>LINN/KÜLA</span>
                            <input id='city' value={city} onChange={(e) => { setCity(e.target.value) }} type='address' placeholder='Suur-Kloostri tn 16' autoComplete="address-level2" ></input>
                        </div>
                        <p id='info' className="font14" style={{ lineHeight: '20px', marginLeft: '20px', color: 'black' }}>
                            Enne kuulutuse loomist peate valge taustaga tabelid läbi lugema, sooritama ülekande, kõik lüngad ära täitma ja märkima kastikesed.
                        </p>
                        <span>Pilt koerast (soovituslik):</span>
                        <ImgSelector image={image} setImage={setImage} maxSize={maxSize} />
                    </div>
                </div>
                <div style={{ display: 'inline-flex', minWidth: '320px', width: '75%', maxWidth: '500px' }}>
                    <div style={{ minWidth: '300px', width: '80%', maxWidth: '400px', margin: '0px auto 0px auto' }}>
                        <Prices time={Time} price={price} discount={discount} total={total} startTime={startTime} endTime={endTime} />
                        <TransactionMenu total={total} userID={userID} />
                    </div>
                </div>
            </div>
            <div style={{ minWidth: '320px', width: '75%', maxWidth: '500px', textAlign: 'center' }}>
                <button onClick={createOfferPost} style={{ backgroundColor: '#247f24', width: '75%' }}>
                    LOO KUULUTUS
                </button>
            </div>
            <Footer />
        </div>
    );
}

export default CreateOffer;
