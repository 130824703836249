import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateOffer from './pages/CreateOffer.js';
import LogIn from './pages/LogInPage.js';
import Profile from './pages/Profile.js';
import WalkerPage from './pages/walkerPage.js'
import LandingPage from './pages/LandingPage.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	//<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<LandingPage title='Dolt | Kodu' />}/>
				<Route path='Profile' element={<Profile title='Dolt | Profiil' />}/>
				<Route path="CreateOffer" element={<CreateOffer title='Dolt | Loo kuulutus' />} />
				<Route path="LogIn" element={<LogIn title='Dolt | Logi sisse' />} />
				<Route path="Walker" element={<WalkerPage title='Dolt | Jalutajale' />} />
			</Routes>
		</BrowserRouter>
	//</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals