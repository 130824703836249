function prices({ time,startTime, endTime ,price, discount, total}) {
    return (
        <div className='InstructionBox' style={{paddingBottom: '10px', marginBottom: '15px'}}>
            <h1 className="font18" style={{margin: '10px' }}>Hinnad</h1>
            <div style={{ height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font14" style={{ marginLeft: '15px', fontWeight: 700 }}>NIMI</span>
                    <span className="font14" style={{ marginLeft: 'auto', marginRight: 'auto', fontWeight: 700 }}>KOGUS({startTime}-{endTime})</span>
                    <span className="font14" style={{ marginRight: '15px', fontWeight: 700 }}>HIND</span>
                </div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font18" style={{ marginLeft: '15px'}}>Kõndimine:</span>
                    <span className="font18" style={{ marginRight: '50px'}}>{time} minutit</span>
                    <span className="font18" style={{ marginRight: '15px'}}>{price}€</span>
                </div>
                {discount > 0 
                        ? <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <span className="font18" style={{ marginLeft: '15px'}}>Teenustasu:</span>
                            <span className="font18" style={{ marginRight: '15px'}}>{discount}€</span>
                        </div>
                        : <div style={{ display: "flex", justifyContent: 'space-between' }}>
                            <span className="font18" style={{ marginLeft: '15px'}}>Soodus:</span>
                            <span className="font18" style={{ marginRight: '15px'}}>{discount}€</span>
                        </div>
                }
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font18" style={{ marginLeft: '15px'}}>Kokku:</span>
                    <span className="font18" style={{ marginRight: '15px'}}>{total}€</span>
                </div>
            </div>
            <input id='DoYouAgree' type='checkbox'></input> <span className="font18" id='DoYouAgreeText'>Nõustun allolevaga</span>
            <p className="font12" style={{ margin: '5px', textAlign: 'justify' }}>
                Märkides selle kasti noolekesega luban, et minu koer ei ohusta koerajalutajat ega teisi linnaruumis ja mujal liiklejaid. Kui peaks juhtuma, et minu koer tekitab teistele varalist või muud kahju, siis mõistan, et olen võtnud vastutuse enda peale.
            </p>
            <p className="font12" style={{margin: '5px', textAlign: 'justify' }}>
                Dolt saab teile lubada, et teeme kõik endast olenevast, et midagi ei juhtuks ei teie koeraga ega teda ümritsejatega. Meie jalutajad vastutavad teie koerte eest täie hoolega ning toovad nad teile tagasi tervetena. Nohu, märjad koerad ja muud sellised tühjad kaebused arutlemise alla ei kuulu ja süüdistused ei päde.
            </p>
        </div>

    );
}

export default prices;
