import '../css/App.css';
import Banner from '../components/banner';
import Cookies from 'js-cookie'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import LogIn from '../components/logIn';


const clientID = '442786804122-b04dvgcnv36ud2liidvlslkijmua15ok.apps.googleusercontent.com'


function LogInPage({ title }) {

    document.title = title



    return (
        <div className="App" style={{ overflow: 'hidden' }}>
            <div style={{position: 'absolute', width: '100%'}}><Banner /></div>
            <GoogleOAuthProvider clientId={clientID} >
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                    <LogIn />
                </div>
            </GoogleOAuthProvider>
        </div>
    );
}

export default LogInPage;
