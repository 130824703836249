import Dropdown from "./dropdown";

function Header({dropdownJumps}) {

    return (
        <div className="LandingPageHeader">
            <img src='doltLogoBlack.svg'></img>
            <Dropdown jumps={dropdownJumps}/>
        </div>
    );
}

export default Header;
