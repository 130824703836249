import '../css/LandingPage.css';
import LandingPageHeader from '../components/landingPageHeader.js'
import Galery from '../components/galery.js'

function LogIn({ title }) {

    document.title = title

    const dropdownJumps = {
        'ÄPP': 'jumpTarget1',
        'MEIST': 'jumpTarget2',
        'MIKS MEIE?': 'jumpTarget3',
        'GALERII': 'jumpTarget4',
        'KONTAKT': 'jumpTarget5'
    }

    return (
        <div className='LandingPage'>
            <LandingPageHeader dropdownJumps={dropdownJumps} />
            <div style={{ display: 'block', minHeight: '500px' }}>
                <img src='/api/gallery?img=doltDog.jpeg' className='phoneImage' style={{ width: '300px', marginTop: '15px' }}></img>
                <div className='firstText'>
                    <h3>Meie jalutame sinu koeraga!</h3>
                    <p style={{ fontSize: '16px', width: '300px' }}>
                        Dolt on lahendus koeraomanikele, kelle kalender
                        on tihedalt täis ja nende elus tekib hetki kus
                        koerale ajast ei jätku.
                    </p>
                </div>
                <div className='largeScreenImage'>
                    <img src='/api/gallery?img=secret/walking1.jpg' />
                    <img src='/api/gallery?img=secret/walking2.jpg' />
                    <img src='/api/gallery?img=secret/walking3.jpg' />
                </div>
            </div>
            <div>
                <div className='title'>
                    <h2 id={dropdownJumps['ÄPP']} >ÄPP</h2>
                </div>
                <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <a href='/Profile'>SISENE ÄPPI</a>
                    <div style={{ display: 'block', textAlign: 'center' }}>
                        <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
                            <p style={{ marginLeft: '0px', marginRight: '0px', marginBottom: '0px' }}>
                                Siit saad siseneda veebiäppi
                            </p>
                            <ul style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <li>Luua konto</li>
                                <li>Luua kuulutus</li>
                                <li>Kuulutuse täitumise progressi jälgida.</li>
                            </ul>
                        </div>
                        <div style={{ display: 'inline-flex', flexDirection: 'column', maxWidth: '600px' }}>
                            <p style={{ marginLeft: '0px', marginRight: '0px', marginBottom: '0px', width: '100%' }}>
                                Jalutajana näed äppist vabasid kuulutusi.
                            </p>
                            <p style={{ marginLeft: '0px', marginRight: '0px', width: '100%' }}>
                                Lähiajal on võimalik Dolt äpp alla laadida vastavalt seadmele App Storei või Google Play kaudu.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='title'>
                    <h2 id={dropdownJumps['MEIST']} >MEIST</h2>
                </div>
                <h3 className='meistTitles'>
                    Oleme õpilasfirma, kes pakub innovatiivset teenust.
                </h3>
                <h4 className='meistTitles'>
                    Ühendame koeraomanikud ja koerajalutajad!
                </h4>
                <h3>
                    MEIE TÖÖTAJAD
                </h3>
                <div className='Us'>
                    <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '10px', marginRight: '10px' }}>
                        <div className='image'>
                            <img src='/api/gallery?img=secret/SamuelLubjak.png' alt='Samuel Lubjak' />
                        </div>
                        <span>Tegevjuht - Samuel Lubjak</span>
                    </div>
                    <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '10px', marginRight: '10px' }}>
                        <div className='image'>
                            <img src='/api/gallery?img=secret/OliverAavik.png' alt='Oliver Aavik' />
                        </div>
                        <span>Tootmisjuht - Oliver Aavik</span>
                    </div>
                    <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '10px', marginRight: '10px' }}>
                        <div className='image'>
                            <img src='/api/gallery?img=secret/RandelRannamagi.png' alt='Randel Rannamägi' />
                        </div>
                        <span>Turundusjuht - Randel Rannamägi</span>
                    </div>
                    <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '10px', marginRight: '10px' }}>
                        <div className='image'>
                            <img src='/api/gallery?img=secret/TrevorMartenLaane.png' alt='Trevor Marten Lääne' />
                        </div>
                        <span>Finantsjuht - Trevor Marten Lääne</span>
                    </div>
                    <div>
                        <h4>PIKEMALT</h4>
                        <p style={{ marginTop: '0px' }}>Oleme Gustav Adolfi Gümnaasiumi õpilasfirma Dolt.
                            Dolt pakub innovatiivset teenust, mis ühendab koeraomanikud ja usaldusväärsed jalutajad!
                            Meie eesmärgiks on pakkuda koeraomanikele mugavust, paindlikkust ja kindlust, et
                            nende koerad saaksid igapäevaselt nautida tervislikke jalutuskäike,
                            isegi siis, kui omanikel on hõivatud ajakava või nad ei saa ise jalutamiseks
                            aega pühendada.
                        </p>
                        <p>
                            Meie platvorm pakub lihtsat viisi, kuidas koeraomanikud saavad leida usaldusväärseid jalutajaid,
                            kes viivad nende koerad jalutama, pakkudes samal ajal koertele vajalikku liikumist ja sotsialiseerumist.
                            Meie teenus ühendab omaniku ja jalutaja otse, et leida parim sobivus koeraomaniku ja jalutaja ajakavale.
                            Kõik meie jalutajad läbivad põhjaliku taustakontrolli, et tagada teie lemmiku turvalisus ja heaolu.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div className='title'>
                    <h2 id={dropdownJumps['MIKS MEIE?']} >MIKS MEIE?</h2>
                </div>
                <div style={{maxWidth: '1200px'}}>
                    <p>
                        Paindlikkus ja mugavus:
                        Broneeri jalutaja täpselt siis, kui seda vajad.
                        Kas siis igapäevaseks jalutuskäiguks või erakorraliseks olukorraks.
                    </p>
                    <p>
                        Professionaalsus: Meie jalutajad on usaldusväärsed, mistõttu saad olla kindel, et su lemmik on heades kätes.
                    </p>
                    <p>
                        Garanteerime õnnelikud koerad.
                    </p>
                    <p style={{ marginBottom: '0px' }}>
                        Me annetame 15% tulust koeravarjupaikadele.
                    </p>
                </div>
                <div className='largeScreenImage'>
                    <img style={{height: '20vw',maxHeight: '300px'}} src='/api/gallery?img=IMG_9811.png' />
                </div>
            </div>
            <div>
                <div className='title'>
                    <h2 id={dropdownJumps['GALERII']}>GALERII</h2>
                </div>
                <Galery />
            </div>
            <div>
                <div className='title'>
                    <h2 id={dropdownJumps['KONTAKT']} >KONTAKT</h2>
                </div>
                <div>
                    <p>
                        Gmail: ofdolt@gmail.com
                    </p>
                    <p>
                        Instagram: @ofdolt
                    </p>
                    <p>
                        Võite meie poole pöörduda igasuguste küsimustega, mis teil tekivad.
                    </p>
                </div>

            </div>
        </div>
    );
}

export default LogIn;
