function Header({ data }) {

    const redirect = (e) => {
        window.location.href = e.target.value;
    }
    return (
        <div className="Header">
            <div style={{ display: 'flex', width: '50%' }}>
                <button value='/Profile' onClick={redirect}>MINU PROFIIL</button>
                <button value='/CreateOffer' onClick={redirect}>LOO KUULTUS</button>
            </div>
            <div style={{width: '10%'}}></div>
            <div style={{ display: 'flex', width: '40%'}}>
                <button value='/' onClick={redirect}>KODU</button>
                <button value='/LogIn' onClick={redirect}>LOGI VÄLJA</button>
            </div>
        </div>
    );
}

export default Header;
