function inputWithHeader({header, autocomplete, id, placeholder, type, value}) {
    return (
        <span className="inputWHeader">
            <span style={{color: 'black'}}>{header}</span>
            <input style={{minWidth: '80px', width: '65%', maxWidth: '220px'}} id={id} type={type} autoComplete={autocomplete} defaultValue={value} placeholder={placeholder}></input>
        </span>
    );
}

export default inputWithHeader;
