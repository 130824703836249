import InputWHeader from '../components/inputWithHeader'

function UserInfo({ userData }) {

    const onFormChange = (e) => {
        e.preventDefault()

        if (e.target.id === 'phone'){
            var regex = /[0-9 +]/g;
            var match = e.target.value.match(regex)
            if(match != null && match.length > 0) {
                e.target.value = match.join('')
                regex = /[0-9+]{1,4}|\d{1,4}/g
                match = e.target.value.match(regex)
                if(match != null && match.length > 0) e.target.value = match.join(' ')
            }
            else{
                e.target.value = ''
            }
        }

        if (e.target.id === 'name' || e.target.id == 'city'){
            var regex = /[^0-9]/g;
            var match = e.target.value.match(regex)
            if(match != null && match.length > 0) {
                e.target.value = match.join('')
            }
            else{
                e.target.value = ''
            }
        }
    }

    const logOut = async(e) => {
        e.preventDefault()
        window.location.href = '/LogIn'
    }

    const sendSaveData = async (e) => {
        e.preventDefault()

        var name = document.getElementById('name').value
        var address = document.getElementById('address').value
        var city = document.getElementById('city').value
        var phone = document.getElementById('phone').value
        
        var output = {'name': name, 'phone': phone, 'address': address, 'city': city}

        console.log(output)
        const response = await fetch("/api/updateUser", {
            method: "POST",
            body: JSON.stringify(output),
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
        });
        window.location.href = '/Profile'
    }

    return (
        <form className="borderdDiv" onChange={onFormChange} style={{position: 'relative', textAlign: 'start', marginLeft: 'auto', marginRight: 'auto', width: '70%', minWidth: '300px', maxWidth: '700px' ,height: '400px'}}>
            <h1 className='title' style={{left: '5%',margin: '0', position: 'absolute', top: '20px'}}>Profiil ({userData.email})</h1>
            <div style={{ display: 'flex',flex: '2' ,flexDirection: 'row', justifyContent: 'space-evenly', height: '75%'}}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%', width: '80%' }}>
                    <div className='userFormRow' >
                        <InputWHeader id='name' header='Nimi' value={userData.name} width='20%' placeholder='Paul Allen' autocomplete='name'/>
                        <InputWHeader  header='Telefon' id='phone' value={userData.phone} width='20%' type='nr' placeholder='+372 5836 4426' autocomplete='tel' />
                    </div>
                    <div className='userFormRow'>
                        <InputWHeader id='address' header='Aadress' value={userData.address} width='20%' placeholder='Suur-Kloostri 16' autocomplete='street-address' />
                        <InputWHeader id='city' header='Linn/küla' value={userData.city} width='20%' placeholder='Tallinn' autocomplete='address-level2' />
                    </div>
                </div>
                <div style={{minWidth: '100px', width: '20vw', maxWidth: '200px'}}>
                    <img style={{marginLeft: '5px', marginRight: '5px', marginTop: '50px', width: '90%'}} src={userData.picture}></img>
                </div>
            </div>
            <div className='userFormRow' style={{height: '25%'}}>
                <button onClick={sendSaveData} style={{ backgroundColor: '#247f24', minWidth: '115px', width: '30%', height: '40px', marginTop: 'auto', marginBottom: 'auto'}}>Salvesta</button>
                <button onClick={logOut} style={{ backgroundColor: '#b23b3b', minWidth: '115px', width: '30%', height: '40px', marginTop: 'auto', marginBottom: 'auto'}} >Logi välja</button>
            </div>
        </form>
    );
}

export default UserInfo;
