import Offer from './Offer'

function OfferList({title, offers, button}) {

    return (
        <div className="borderdDiv" style={{height: '100%',width: '100%' ,marginTop: '0px' ,overflowY: 'scroll'}}>
            <span className='title' style={{marginTop: '25px', marginBottom: '0px'}}>{title}</span>
                {offers.map((offer, key) => (
                    <div key={key}>
                        <Offer data={offer} button={button} />
                    </div>
                ))}
        </div>
    );
}

export default OfferList;
