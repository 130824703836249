function transactionMenu({ userID, total}) {
    return (
        <div className="InstructionBox" style={{marginBottom: '15px', paddingBottom: '10px'}}>
            <h1 className="font18" style={{margin: '10px'}}>Sooritage ülekanne</h1>
            <div style={{ height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font14" style={{ marginLeft: '15px', fontWeight: 700 }}>SAAJA:</span>
                    <span className="font14" style={{ marginRight: '15px' }}>TREVOR MARTEN LÄÄNE</span>
                </div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font14" style={{ marginLeft: '15px', fontWeight: 700 }}>KONTO NR:</span>
                    <span className="font14" style={{ marginRight: '15px', }}>EE707700771010859800</span>
                </div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font14" style={{ marginLeft: '15px', fontWeight: 700 }}>SUMMA:</span>
                    <span className="font14" style={{ marginRight: '15px'}}>{total}EUR</span>
                </div>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <span className="font14" style={{ marginLeft: '15px', fontWeight: 700 }}>SELGITUS:</span>
                    <span className="font14" style={{ marginRight: '15px'}}>"Kõndimis tasu #{userID}"</span>
                </div>
            </div>
            <input  id='trasactionDone' type="checkbox"></input> <span className="font18" id='trasactionDoneText'>Olen sooritanud ülekande</span>
        </div>

    );
}

export default transactionMenu;
