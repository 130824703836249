function Offer({data, button}) {

    const undefinedImage = 'https://i0.wp.com/digitalhealthskills.com/wp-content/uploads/2022/11/3da39-no-user-image-icon-27.png?fit=500%2C500&ssl=1'

    function parseDate(str, format='yyyy-mm-dd') {
        const parts = str.match(/(\d+)/g);
        const fmt = {};
        let i = 0;
    
        format.replace(/(yyyy|mm|dd)/g, (part) => {
            fmt[part] = i++;
        });

        const date = new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
        return date
    }

    function formatDateInEstonian(date) {
        return date.toLocaleDateString('et-EE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    return (
        <div className="offer">
            <div style={{maxWidth: '200px', flex: '1'}}>
                {data.image == undefined
                    ? <img src={'missingDog.svg'} alt="dog"></img>
                    : <img src={'api/image?id=' + data.id} alt="dog"></img>
                }
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    {button == undefined
                        ? ''
                        : <button value={data.id} onClick={button.function} style={button.style}>{button.text}</button>
                    }
                </div>
                
            </div>
            <div style={{display: "flex", flexDirection: 'column', marginRight: '5px'}}>
                <span className="font24" style={{fontWeight: '700'}}>{data.name}</span>
                <span>Kuupäev: {formatDateInEstonian(parseDate(data.date))}</span>
                <span>Kellaaeg: {data.startTime}-{data.endTime}</span>
                <span>Asukoht: {data.location}</span>
                <span>Omanik: {data.ownerName} ({data.phone})</span>
                <span>Jalutaja: 
                {data.walkerID == undefined
                    ? ' Otsitakse...'
                    : ' ' + data.walkerName + ' (' + data.walkerPhone +')'
                }
                </span>
            </div>
        </div>
    );
  }
  
  export default Offer;
  